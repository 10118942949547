<template>
  <div>
    <h3>
      {{ $t("campaigns") }}
      <span class="text-muted">{{ count }}</span>
    </h3>
    <p class="text-muted">{{ $t("campaignsText") }}</p>
    <div class="d-flex justify-content-end">
      <button class="blue-button" @click="campaignsShowModal = true">
        {{ $t("addCampaigns") }}
      </button>
    </div>
    <Alert :alert="alert" class="mt-2" />
    <BaseTable
      i18n
      :loading="isLoading"
      :items="items"
      :count="count"
      :limit="10"
      @change:search="SET_SEARCH"
      :page="page"
      @change:page="SET_PAGINATION"
      :searchPlaceholder="$t('searchCampaingText')"
      :pages="pages"
      :headers="headers"
      noCaption
    >
      <template #title="{ item }">
        <strong>
          <font-awesome-icon :icon="faGifts" />

          {{ item.title }}</strong
        >
        <div>
          <small class="text-muted">
            <font-awesome-icon :icon="faCalendar" />

            {{ setDate(item.createdDate) }}</small
          >
        </div>
      </template>
      <template #code="{ item }">
        <span class="badge badge-soft-success px-1 w-100">
          {{ item.code }}</span
        >
      </template>
      <template #person="{ item }">
        {{ item.assignedUserName }}
      </template>
      <template #companyCode="{ item }">
        {{ item.isCompanyCode ? $t("companyCode") : $t("campaignCode") }}
      </template>
      <template #expiredAt="{ item }">
        <div>
          <small class="text-muted">
            <font-awesome-icon :icon="faCalendar" />

            {{
              item.unrestricted ? $t("indefinitely") : setDate(item.expireDate)
            }}</small
          >
        </div>
      </template>
      <template #isActive="{ item }">
        <div class="text-right text-md-left">
          <div class="position-relative">
            <small
              :class="`w-100 badge badge-soft-${
                item.isActive ? 'success' : 'danger'
              }`"
              >{{ $t(item.isActive ? $t("active") : $t("notActive")) }}</small
            >
          </div>
        </div></template
      >
      <template #actions="{ item }">
        <div class="d-flex justify-content-end actions-button">
          <div class="mr-3" @click="() => editCampaignId(item.id)">
            <span class="badge badge-soft-dark px-1">
              <font-awesome-icon :icon="faPencil" />
            </span>
          </div>
          <div @click="() => deleteCampaignAction(item.id)">
            <span class="badge badge-soft-dark px-1">
              <font-awesome-icon :icon="faTrash" />
            </span>
          </div>
          <router-link :to="`/admin/campaigns/user/${item.id}`" class="ml-3">
            <button type="button" class="blue-button btn-xs font-size-15">
              {{ $t("seeDetail") }}
              <font-awesome-icon :icon="faEye" />
            </button>
          </router-link>
        </div>
      </template>
    </BaseTable>
    <b-modal
      centered
      v-model="campaignsShowModal"
      :title="$t('addCampaigns')"
      class="admin-list"
      hide-footer
      size="md"
      @hidden="resetModal"
      id="campany-form"
    >
      <div class="form-style account-settings p-4">
        <Alert :alert="alert" />
        <b-form
          @submit.prevent="
            campaignId === null ? addCampaignAction() : editCampaignAction()
          "
        >
          <b-form-group>
            <label for="title">{{ $t("campany.campaignName") }}</label>
            <b-form-input
              type="text"
              id="title"
              v-model="$v.form.title.$model"
              :state="!$v.form.title.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.title.required">{{
              $t("campany.campaignCodeName")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <label for="code">{{ $t("discountCode") }}</label>
            <b-form-input
              type="text"
              id="code"
              v-model="$v.form.code.$model"
              :state="!$v.form.code.$error"
              :disabled="campaignId !== null"
            />
            <b-form-invalid-feedback v-if="!$v.form.code.required">{{
              $t("enterDiscountCode")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <label for="assignedUserName">{{
              $t("campany.appointedName")
            }}</label>
            <b-form-input
              type="text"
              id="assignedUserName"
              v-model="$v.form.assignedUserName.$model"
              :state="!$v.form.assignedUserName.$error"
            />
            <b-form-invalid-feedback
              v-if="!$v.form.assignedUserName.required"
              >{{ $t("campany.nameDiscountCode") }}</b-form-invalid-feedback
            >
          </b-form-group>
          <div class="mb-3">
            <label class="mr-2 w-100">{{ $t("campany.discountType") }}</label>
            <div
              class="row align-items-center mb-3 married-form-margin col-12 ml-0"
            >
              <div class="col-12 col-md-6 col-xl-6 active-radio-button">
                <div class="radio-button">
                  <input
                    type="radio"
                    id="percent"
                    name="priceMethod"
                    value="1"
                    v-model="form.discountType"
                    :disabled="campaignId !== null"
                  />
                  <label for="percent" class="mr-2 w-100">{{
                    $t("campany.percentType")
                  }}</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-6">
                <div class="radio-button">
                  <input
                    type="radio"
                    id="price"
                    name="priceMethod"
                    value="2"
                    v-model="form.discountType"
                    :disabled="campaignId !== null"
                  />
                  <label for="price" class="mr-2 w-100">{{
                    $t("campany.priceType")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="mr-2 w-100">{{ $t("codeType") }}:</label>
            <div
              class="row align-items-center mb-3 married-form-margin col-12 m-0"
            >
              <div class="col-12 col-md-6 col-xl-6 active-radio-button">
                <div class="radio-button">
                  <input
                    type="radio"
                    id="companyCode"
                    name="code"
                    :value="true"
                    v-model="form.isCompanyCode"
                  />
                  <label for="companyCode" class="mr-2 w-100">{{
                    $t("company")
                  }}</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-6">
                <div class="radio-button">
                  <input
                    type="radio"
                    id="campaignCode"
                    name="code"
                    :value="false"
                    v-model="form.isCompanyCode"
                  />
                  <label for="campaignCode" class="mr-2 w-100">{{
                    $t("campaing")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <b-form-group>
            <label for="paymentPrice">{{
              $t("campany.discountAmountRate")
            }}</label>
            <b-form-input
              type="text"
              id="paymentPrice"
              v-model="$v.form.discount.$model"
              :state="!$v.form.discount.$error"
              :disabled="campaignId !== null"
            />
            <b-form-invalid-feedback v-if="!$v.form.discount.required">{{
              $t("campany.discountAmountRateEnter")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <label for="description">{{
              $t("campany.campaignDescription")
            }}</label>
            <b-form-textarea
              id="description"
              v-model="$v.form.description.$model"
              :state="!$v.form.description.$error"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-form-invalid-feedback v-if="!$v.form.description.required">
              {{ $t("campany.descriptionEnter") }}
            </b-form-invalid-feedback>
          </b-form-group>
          <label for="expireDate">{{ $t("expiredAt") }}</label>
          <div class="mb-3 row">
            <div class="col-12 col-lg-6 col-xl-6">
              <v-date-picker
                v-model="form.expireDate"
                :locale="$i18n.locale"
                is-required
                :popover="{ visibility: 'click' }"
                id="expireDate"
                :min-date="new Date()"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <!-- <b-form-input
                    :value="form.unrestricted ? '' : inputValue"
                    v-on="inputEvents"
                    readonly
                    :disabled="form.unrestricted"
                    :style="{
                      backgroundColor: form.unrestricted ? '#e9ecef' : '#fff',
                    }"
                    :placeholder="$t('date')"
                  /> -->
                  <input
                    class="form-control"
                    :style="{
                      backgroundColor: form.unrestricted ? '#e9ecef' : '#fff',
                    }"
                    :value="form.unrestricted ? '' : inputValue"
                    v-on="inputEvents"
                    :disabled="form.unrestricted"
                    :placeholder="$t('date')"
                  />
                </template>
              </v-date-picker>
            </div>
            <div class="radio-button col-12 col-lg-6 col-xl-6 mobile-top">
              <input
                type="checkbox"
                id="indefinite"
                name="date"
                :value="true"
                v-model="form.unrestricted"
              />
              <label for="indefinite" class="mr-2 w-100">{{
                $t("indefinitely")
              }}</label>
            </div>
          </div>
          <div class="mb-3" v-if="campaignId !== null">
            <label class="mr-2 w-100">{{ $t("campany.campaignActive") }}</label>
            <div
              class="row align-items-center mb-3 married-form-margin col-12 ml-0"
            >
              <div class="col-12 col-md-6 col-xl-6 active-radio-button">
                <div class="radio-button">
                  <input
                    type="radio"
                    id="active"
                    name="status"
                    :value="true"
                    v-model="form.isActive"
                  />
                  <label for="active" class="mr-2 w-100">{{
                    $t("active")
                  }}</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-6">
                <div class="radio-button">
                  <input
                    type="radio"
                    id="notActive"
                    name="status"
                    :value="false"
                    v-model="form.isActive"
                  />
                  <label for="notActive" class="mr-2 w-100">{{
                    $t("notActive")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center w-100">
            <button class="blue-button w-100 mt-3">
              <span v-if="!loading">{{ $t("save") }}</span>
              <b-spinner
                variant="light"
                class="font-size-14"
                v-else
              ></b-spinner>
            </button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BaseTable from "../../base/Table/index.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faEye,
  faPhone,
  faEnvelope,
  faCalendar,
  faUser,
  faEyeSlash,
  faPencil,
  faTrash,
  faGifts,
} from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import Alert from "../../base/alert/Alert.vue";
import {
  addCampaign,
  deleteCampaign,
  editCampaign,
  getCampaign,
  getInfoCampaign,
} from "../../../services/modules/Campaign";

const { required, email } = require("vuelidate/lib/validators");

export default {
  components: {
    BaseTable,
    FontAwesomeIcon,
    Alert,
  },
  data() {
    return {
      faEye,
      faEyeSlash,
      faPhone,
      faUser,
      faCalendar,
      faEnvelope,
      faPencil,
      faTrash,
      faGifts,
      search: "",
      status: -1,
      statusList: [
        { value: -1, text: "allStatus" },
        { value: 0, text: "waiting" },
        { value: 1, text: "reviewing" },
        { value: 2, text: "completed" },
      ],
      headers: [
        {
          title: "discountCodeName",
          value: "title",
          col: 12,
          md: 6,
          lg: 2,
        },
        { title: "discountCode", value: "code", col: 3, md: 3, lg: 2 },
        { title: "appointedPerson", value: "person", col: 3, md: 3, lg: 2 },
        { title: "codeType", value: "companyCode", col: 3, md: 3, lg: 2 },
        { title: "expiredAt", value: "expiredAt", col: 3, md: 3, lg: 1 },
        { title: "status", value: "isActive", col: 3, md: 3, lg: 1 },
        { title: "", value: "actions", col: 3, md: 3, lg: 2 },
      ],
      count: 0,
      page: 1,
      items: [],
      limit: 10,
      isLoading: true,
      activeUpdateStatus: -1,
      campaignsShowModal: false,
      loading: false,
      form: {
        title: "",
        code: "",
        assignedUserName: "",
        discountType: 1,
        discount: "",
        description: "",
        expireDate: null,
        unrestricted: true,
        isActive: true,
        isCompanyCode: false,
      },
      passEye: false,
      roles: [
        {
          title: "Admin",
          value: "Admin",
        },
        {
          title: "Manager",
          value: "Manager",
        },
      ],
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      campaignId: null,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: {
        required,
      },
      code: {
        required,
      },
      assignedUserName: {
        required,
      },
      discount: {
        required,
      },
      description: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["activeUser"]),
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  watch: {
    "form.unrestricted"(val) {
      if (val) {
        this.form.expireDate = null;
      }
    },
  },
  methods: {
    async addCampaignAction() {
      this.loading = true;
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        const response = await addCampaign(this.form);

        if (response.message === "OK") {
          this.alert.show = true;
          this.alert.variant = "success";
          this.alert.message = "saveSuccess";
          setTimeout(() => {
            location.reload();
          }, 3000);
        } else {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "notSaveSuccess";
        }

        setTimeout(() => {
          this.alert.show = false;
        }, 3000);
      }

      this.loading = false;
    },
    changeRole(val) {
      this.form.role = val;
    },
    async infoCampaign() {
      const response = await getInfoCampaign(this.campaignId);

      if (response.message === "OK") {
        this.form = {
          ...response.data,
        };
      }
    },
    editCampaignId(val) {
      this.campaignId = val;
      this.campaignsShowModal = true;
      this.infoCampaign();
    },
    async editCampaignAction() {
      this.loading = true;
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        const response = await editCampaign(this.form, this.campaignId);

        if (response.message === "OK") {
          this.alert.show = true;
          this.alert.variant = "success";
          this.alert.message = "successAdminMessage";

          setTimeout(() => {
            location.reload();
          }, 3000);
        } else {
          this.alert.show = true;
          this.alert.variant = "success";
          this.alert.message = "notSuccessAdminMessage";
        }
      }
      this.loading = false;

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
    resetModal() {
      this.campaignId = null;
      this.form = {
        title: "",
        code: "",
        assignedUserName: "",
        discountType: 1,
        discount: "",
        description: "",
        expireDate: null,
        unrestricted: true,
        isCompanyCode: false,
        isActive: true,
      };
    },
    async deleteCampaignAction(val) {
      const response = await deleteCampaign(val);

      if (response.message === "OK") {
        const index = this.items.findIndex((item) => item.id === val);
        this.items.splice(index, 1);
        this.alert.show = true;
        this.alert.variant = "success";
        this.alert.message = "successAdminMessage";
      } else {
        this.alert.show = true;
        this.alert.variant = "success";
        this.alert.message = "notSuccessAdminMessage";
      }

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
    SET_STATUS_FILTER() {
      this.page = 1;
      this.$router.push({
        query: { ...this.$route.query, page: 1, status: this.status },
      });
      this.getCampaignList();
    },
    SET_SEARCH(search) {
      this.search = search;
      this.page = 1;
      this.$router.push({ query: { ...this.$route.query, search, page: 1 } });
      this.getCampaignList(this.search, this.page, this.limit);
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.push({ query: { ...this.$route.query, page } });
      this.getCampaignList(this.search, this.page, this.limit);
    },
    async getCampaignList() {
      this.isLoading = true;
      const response = await getCampaign(this.search, this.page, this.limit);
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      } else {
        this.items = [];
        this.count = 0;
      }
      this.isLoading = false;
    },
    setDate(date) {
      return new Date(date + "+00:00").toLocaleString(this.$i18n.locale, {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  mounted() {
    const q = this.$route.query;
    if (!isNaN(q.page)) this.page = Number(q.page);
    if (!isNaN(q.status)) this.status = Number(q.status);
    if (q.search) this.search = q.search;
    this.getCampaignList();
  },
};
</script>

<style lang="scss">
.account-settings {
  .lang-select-box {
    border-radius: 20px;
    border: 2px solid #01295f;
    padding: 0 1rem;
  }
}
.status-option-dropdown {
  position: absolute;
  top: 0px;
  width: 100%;
  background: white;
  z-index: 11;
  &__button {
    cursor: pointer;
  }
}
.password-icon {
  position: relative;

  .input-icon {
    position: absolute;
    top: 60%;
    right: 1.2rem;
    color: #01295f;
  }
}
.phone-form {
  .area-code {
    position: absolute;
    top: 52%;
    left: 20px;
  }

  .phone-input {
    position: relative;

    input {
      padding-left: 70px;
    }
  }
}

#campany-form {
  .radio-button {
    border: 2px solid #161640;
    padding: 0px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    flex-direction: row-reverse;

    input {
      height: 22px;
      width: 22px;

      &:focus {
        box-shadow: none;
      }
    }

    input[type="radio"]:checked ~ label,
    input[type="checkbox"]:checked ~ label {
      color: rgb(87, 198, 255);
    }

    input[type="radio"]:checked ~ .radio-button,
    input[type="checkbox"]:checked ~ .radio-button {
      border: 1px solid red;
    }

    input[type="radio"]:checked {
      position: relative;

      &::before {
        content: "\2713";
        position: absolute;
        background-color: rgb(87, 198, 255);
        color: white;
        height: 22px;
        width: 22px;
        border-radius: 50rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    input[type="checkbox"]:checked {
      position: relative;

      &::before {
        content: "\2713";
        position: absolute;
        background-color: rgb(87, 198, 255);
        color: white;
        height: 22px;
        width: 22px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    label {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      height: 36px;
    }
  }

  .modal-content {
    max-height: 650px !important;
    overflow-y: scroll !important;
  }
}

.actions-button {
  span {
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .married-form-margin {
    .active-radio-button {
      margin-bottom: 20px;
    }
  }

  .mobile-top {
    margin-top: 20px;
  }
}
</style>