import { postJsonToken } from '../Api/postJsonToken'
import { getToken } from '../Api/getToken'
import { putToken } from '../Api/putToken'
import { deleteToken } from '../Api/deleteToken'
import { getNoToken } from '../Api/getNoToken'

// Kampanya ekle
export const addCampaign = (form) => {
    let data = {
        "title": form.title,
        "assignedUserName": form.assignedUserName,
        "code": form.code,
        "description": form.description,
        "discountType": form.discountType,
        "discount": form.discount,
        "expireDate": form.expireDate,
        "unrestricted": form.unrestricted,
        "isCompanyCode": form.isCompanyCode,
    }
    return postJsonToken('/Campany', data)
}

//Kampanyaları listele
export const getCampaign = (search, page, limit) => {
    return getToken(`/Campany?search=${search}&page=${page}&limit=${limit}`)
}

//Kampanya özelliklerini getirme
export const getInfoCampaign = (id) => {
    return getToken(`/Campany/${id}`)
}

//Kampanya bilgileri düzenleme
export const editCampaign = (form, id) => {
    let data = {
        "title": form.title,
        "assignedUserName": form.assignedUserName,
        "code": form.code,
        "description": form.description,
        "discountType": form.discountType,
        "discount": form.discount,
        "expireDate": form.expireDate,
        "unrestricted": form.unrestricted,
        "isActive": form.isActive,
        "isCompanyCode": form.isCompanyCode
    }
    return putToken(`/Campany/${id}`, data)
}

//Kampanyayı silme
export const deleteCampaign = (id) => {
    return deleteToken(`/Campany/${id}`)
}

//Kampanyaya bağlı olan kullanıcıları listeleme
export const getCampaignUsers = (page, limit, id) => {
    return getToken(`/Campany/GetCampanyUsers/${id}?page=${page}&limit=${limit}`)
}

//Fiyata indirim kodu ekleme
export const addDiscountCode = (code, userId, isPartnerPayment) => {
    return getNoToken(`/Campany/CheckCampanyCode?code=${code}&userId=${userId}&isPartnerPayment=${isPartnerPayment}`)
}