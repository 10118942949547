<template>
  <div>
    <Campaigns></Campaigns>
  </div>
</template>

<script>
import Campaigns from "@/components/admin/campaigns";

export default {
  components: {
    Campaigns,
  },
};
</script>

<style>
</style>