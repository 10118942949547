<template>
  <b-alert :show="alert.show" :variant="alert.variant">{{
    $t(alert.message)
  }}</b-alert>
</template>

<script>
export default {
  props: {
    alert: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.alert {
  border-radius: 20px;
  font-size: 14px;
}
</style>